<template>
    <div class="">
        <div class="">

            <!-- /.content-header -->
            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <!-- Main row -->
                    <div class="row">
                        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                            <div class="card">
                                <div class="card-body table-responsive table-head">
                                    <div class="row bor-bot">
                                    <div class="col-md-8 pl-0 mb-3">
                                       <h1 class="m-0 text-dark pt-2 pb-2">Insurance Certificate Fulfillment</h1>
                                    </div>
                                    <div class="col-md-4"> 
                                        <div class="text-right pb-3">
                                        <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                        <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                        </div>   
                                    </div>
                                  </div>
                                    <div class="table-responsive mt-3">
                                        <div class="data-tablebutn pb-3">
                                            <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-insurance-certificate-report>
                                                <i class="fas fa-sliders-h"></i> Filters
                                            </b-button>
                                            <b-button class="btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied">
                                                <i class="fas fa-redo"></i> Reset
                                            </b-button>
                                            <b-button class="ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                                                <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export
                                            </b-button>
                                        </div>
                                        <table class="table table-sm table-bordered table-hover" id="list_table">
                                            <thead class="thead-dark">
                                            <tr>
                                                <th class="all">Date Paid</th>
                                                <th class="all">Fulfilled</th>
                                                <th class="all">Date Fullfilled</th>
                                                <th class="all">Club Name</th>
                                                <th class="all">Club Member#</th>
                                                <th class="all">Start Date</th>
                                                <th class="all">End Date</th>
                                                <th class="all">Club Contact Name</th>
                                                <th class="all">Club Contact E-Mail</th>
                                                <th class="all">Club Contact Phone</th>
                                                <th class="none">Additional Insured Name</th>
                                                <th class="none">Additional Insured Physical Address with City, State and Zip</th>
                                                <th class="none">Phone Number</th>
                                                <th class="none">Certificate For Year Round Activity OR Specific Event</th>
                                                <th class="none">If for Specific Event Name of the event</th>
                                                <th class="none">If for Specific Event Date of the event</th>
                                                <th class="none">If for Specific Event Sanction Number</th>
                                                <th class="none">Liability Agreement Held</th>
                                                <th class="none">Additional Insured Status Required</th>
                                                <!-- <th>Actions</th> -->
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <!-- Data will be placed here -->
                                            <tr>
                                                <td colspan="19">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="19">&nbsp;</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <b-modal id="modal-insurance-certificate-report" v-bind:hide-footer="true" ref="modal-insurance-certificate-report" size="lg"  title="Advanced Search">
                                            <form ref="form" method="post" @submit.prevent="advancedSearch()">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="transaction_start_date" class="form-label">From Date</label>
                                                            <div class="input-group birthdate">
                                                                <div class="birthdaypicker">
                                                                    <date-pick
                                                                        v-model="searchForm.transaction_start_date"
                                                                        :format="'MM/DD/YYYY'"
                                                                        :displayFormat="'MM/DD/YYYY'"
                                                                        v-mask="'##/##/####'"
                                                                        :parseDate="parseDatePick"
                                                                    ></date-pick>
                                                                </div>
                                                            </div>
                                                            <input id="transaction_start_date" type="hidden" class="form-control" :value="transaction_start_date_1">
                                                            <div class="text-sm text-danger">{{ errors.transaction_start_date }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="transaction_end_date" class="form-label">To Date</label>
                                                            <div class="input-group birthdate">
                                                                <div class="birthdaypicker">
                                                                    <date-pick
                                                                        v-model="searchForm.transaction_end_date"
                                                                        :format="'MM/DD/YYYY'"
                                                                        :displayFormat="'MM/DD/YYYY'"
                                                                        v-mask="'##/##/####'"
                                                                        :parseDate="parseDatePick"
                                                                    ></date-pick>
                                                                </div>
                                                            </div>
                                                            <input id="transaction_end_date" type="hidden" class="form-control" :value="transaction_end_date_1">
                                                            <div class="text-sm text-danger">{{ errors.transaction_end_date }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="fulfilled" class="form-label">Fulfilled / Unfulfilled</label>
                                                            <select name="fullfilled" id="fullfilled" class="form-control" v-model="searchForm.fullfilled">
                                                                <option value="">Select</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="fulfilled_date" class="form-label">Fulfilled Date</label>
                                                            <div class="input-group birthdate">
                                                                <div class="birthdaypicker">
                                                                    <date-pick
                                                                        v-model="searchForm.fulfilled_date"
                                                                        :format="'MM/DD/YYYY'"
                                                                        :displayFormat="'MM/DD/YYYY'"
                                                                        v-mask="'##/##/####'"
                                                                        :parseDate="parseDatePick"
                                                                    ></date-pick>
                                                                </div>
                                                            </div>
                                                            <input id="fulfilled_date" type="hidden" class="form-control" :value="fulfilled_date_1">
                                                            <div class="text-sm text-danger">{{ errors.fulfilled_date }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Club Name</label>
                                                            <input type="text" id="club_name" class="form-control" v-model="searchForm.club_name">
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Club Member Id</label>
                                                            <input type="text" id="confirmation_code" class="form-control" v-model="searchForm.confirmation_code">
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <div class="col-md-12 modal-searchbtn text-center">
                                                        <div class="form-group">
                                                            <button type="submit" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                                                        </div>
                                                        <div class="text-sm text-danger">{{ errors.not_found }}</div>
                                                    </div>
                                                </div>
                                            </form>
                                        </b-modal>
                                    </div>
                                    </div>
                        </section>
                    </div>
                    <!-- /.row (main row) -->
                </div><!-- /.container-fluid -->
            </section>
        </div>
        <!-- /.content -->
    </div>
</template>

<script>
  import $ from "jquery";
//   import DownloadExcel from "vue-json-excel";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

  import moment from 'moment';

  import DatePick from "vue-date-pick";
  import axios from 'axios';

  const validateName = name => {
      if (name.length || name != "") {
          return { valid: true, error: null };
      }
      return { valid: false, error: null };
  };

  let cachedData = {};
  export default {
      components: { DatePick },
      el:'#memreport',
    data() {
      return {
          exportProgress: false,
          filtersApplied: false,
          loaded: false,
          filter: null,
          filterOn: [],
          infoModal: {
              id: 'info-modal',
              title: '',
              content: ''
          },
          searchForm: {
            transaction_start_date: '',
            transaction_end_date: '',
            fullfilled: '',
            fulfilled_date: '',
            club_name: '',
            confirmation_code:''
          },
          button: {
              SearchButton: 'Search'
          },
          errors: {},
          table: {
 
          },
          term: ''
        }
     },
      methods: {
          advancedSearch() {
              if(!this.validate()) {
                  return;
              }
              this.button.SearchButton = 'Searching...';
              // Prevent modal from closing
              //bvModalEvt.preventDefault()
              this.loaded = false;
              // Trigger submit handler
              this.table.draw();
              this.filtersApplied = true;
              this.$refs['modal-insurance-certificate-report'].hide();
              this.button.SearchButton = 'Search';
          },
          // Custom Validation Rules
          validate() {
            this.errors = {}
            this.valid = true;

            const validTStartDate = validateName(this.searchForm.transaction_start_date);
            this.errors.transaction_start_date = validTStartDate.error;

            const validTEndDate = validateName(this.searchForm.transaction_end_date);
            this.errors.transaction_end_date = validTEndDate.error;
            
            let dateValid = false;
            if(validTStartDate.valid && !this.validateDate(this.searchForm.transaction_start_date)){
                dateValid = true;
                this.errors.transaction_start_date = "Invalid Date";
            }
            if(validTEndDate.valid && !this.validateDate(this.searchForm.transaction_end_date)){
                dateValid = true;
                this.errors.transaction_end_date = "Invalid Date";
            }

            if(dateValid == false){
                if((validTStartDate.valid && validTEndDate.valid) && (moment(this.searchForm.transaction_end_date).isBefore(this.searchForm.transaction_start_date))) {
                    this.errors.transaction_end_date = 'End date must be greater than Start date!';
                    return this.valid = false;
                } else {
                    this.valid = true;
                }
            } else {
                this.valid = false;
            }

            if(this.searchForm.fulfilled_date != "" && !this.validateDate(this.searchForm.fulfilled_date)){
                this.errors.fulfilled_date = "Invalid Date";
                this.valid = false;
            }

            return this.valid;
        },
        refreshList(event){
            this.searchForm = {
                transaction_start_date: '',
                transaction_end_date: '',
                fullfilled: '',
                fulfilled_date: '',
                club_name: '',
                confirmation_code:''
            };
            // this.table.clear().draw();
            this.table.ajax.reload();
            this.filtersApplied = false;
        },
        exportCsv(){
            var self = this;
            $.ajax({
                url: process.env.VUE_APP_URL + "api/reports/export/insurance-certificates",
                type: 'post',
                "data": this.table.ajax.params(),
                beforeSend: function(){
                    self.exportProgress = true;
                },
                "success": function(res, status, xhr) {
                    self.exportProgress = false;
                    if(res.file_url != undefined) window.open(res.file_url, '_blank');
                },
                error: function(err){
                    console.log(err);
                    self.exportProgress = false;
                }
            });
        }
      },
      watch: {
        '$route.query': 'Search',
      },
      mounted() {
        var self = this;
        this.table = $("#list_table").DataTable({
            dom: "lfrtip",
            processing: true,
            serverSide: true,
            bFilter: true,
            responsive: true,
            ajax: {
                url: process.env.VUE_APP_URL + "api/report/club_insurance_certificate_fulfillment",
                type: 'post',
                data: function (d) {
                    d.insurance_created_start_date = $("#transaction_start_date").val() || self.searchForm.transaction_start_date;
                    d.insurance_created_end_date = $("#transaction_end_date").val() || self.searchForm.transaction_end_date;
                    d.fulfilled = $("#fullfilled").val() || self.searchForm.fullfilled;
                    d.fulfilled_date = $("#fulfilled_date").val() || self.searchForm.fulfilled_date;
                    d.team_name = $("#club_name").val() || self.searchForm.club_name;
                    d.team_member_id = $('#confirmation_code').val() || self.searchForm.confirmation_code;
                }
            },
            columns: [
                { data: "transaction_time", name: "transaction_time", render: (data,type,row,meta) => { return data ? moment(data).format("MM/DD/YYYY") : "N/A"; } },
                { data: "fulfilled", name: "fulfilled", orderable: false, searchable: false },
                { data: "fulfilled_date", name: "fulfilled_date", orderable: false },
                { data: "club_name", name: "team.name" },
                { data: "club_member_id", name: "team.members.confirmation_code", orderable: false, searchable: true },
                { data: "start_date", name: "start_date", orderable: false, render: (data) => { return data ? moment(data).format("MM/DD/YYYY") : "N/A"; } },
                { data: "end_date", name: "end_date", orderable: false, render: (data) => { return data ? moment(data).format("MM/DD/YYYY") : "N/A"; }  },
                { data: "tc_name", name: "team.team_contacts.first_name", orderable: false, searchable: true, render: (data)=>{return data || "N/A";} },
                { data: "tc_email", name: "team.team_contacts.email", orderable: false, searchable: true, render: (data)=>{return data || "N/A";} },
                { data: "tc_phone", name: "team.team_contacts.phone_1", orderable: false, searchable: true, render: (data)=>{return data || "N/A";} },
                { data: "insurer_name", name: "company_name" },
                { data: "additional_insured_address", name: "address1" },
                { data: "home_phone", name: "home_phone", orderable: false },
                { data: "insurance_type", name: "certificate_type", orderable: false },
                { data: "specific_event_title", name: "specific_event_title", orderable: false },
                { data: "specific_event_from_to_date", name: "specific_event_from_to_date", orderable: false, searchable: false, render: (data) => { return data || ""; }  },
                { data: "event_sanction", name: "event_sanction", orderable: false },
                { data: "team_has_any_agreement", name: "team_has_any_agreement", render: data => data == "Y" ? "Yes" : 'No', orderable: false, searchable: true },
                { data: "additional_insurance", name: "additional_insurance", render: data => data == "Y" ? "Yes" : 'No', orderable: false, searchable: true }
            ],
            order: [[0, "desc"]]
        });

        let headers = {headers: this.adminHeaders};
        let url = this.basePath + "api/update_club_insurance";
        document.addEventListener("change", function(e){
            if(e.target.className=='fulfilledChk') {
                const checked = e.target;
                let updateFulfilledData={
                    id:e.target.dataset.id,
                    fulfilled: (checked.checked?1:0)
                };
                axios.post( url, updateFulfilledData, headers)
                    .then(
                        function(response) {
                            $("#list_table").DataTable().ajax.reload(null, false); 
                        }.bind(this)
                    )
                    .catch(function(error) {
                        console.log(error);
                    });
            }
        })
      },
      computed: {
          transaction_start_date_1: function () {
              return this.searchForm.transaction_start_date;
          },
          transaction_end_date_1: function(){
              return this.searchForm.transaction_end_date;
          },
          fulfilled_date_1: function(){
              return this.searchForm.fulfilled_date;
          }
      }
    }

</script>
